.image {
    margin: 0 0 0 60px;
    display: flex;
    align-items: center;
}

.monsterCard {
    margin-top: 20px;
    background: url('../../assets/images/stat-block-top-texture.png'),url('../../assets/images/paper-texture.png');
    background-size: 100% auto;
    background-position: top center;
    background-repeat: no-repeat no-repeat;
}