body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
  max-width: 500px;
  white-space: pre-wrap;       /* css-3 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

@font-face {
  font-family: 'Nodesto Caps Condensed';
  src: url('./assets/fonts/Nodesto Caps Condensed.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Bookinsanity';
  src: url('./assets/fonts/Bookinsanity.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Mr Eaves Small Caps';
  src: url('./assets/fonts/Mr Eaves Small Caps.otf') format('opentype');
  font-weight: normal;
}