.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.logo {
  width: 520px;
  height: 31px;
  margin: 16px 24px 16px 0;
  float: left;
  color: white;
  line-height: 30px;
  user-select: none;
  font-family: 'Nodesto Caps Condensed', Helvetica, sans-serif;
  font-size: 3em;
}

.time-card {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 250px;
  transition: background-image 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-card .ant-typography {
  color: white;
  mix-blend-mode: difference;
}