.initiativeBoxLight {
    transition: all 0.2s linear;
    border: 2px solid #d9d9d9!important;
}

.initiativeBoxDark {
    transition: all 0.2s linear;
    border: 2px solid #505050!important;
}

.initiativeBoxLight.active, .initiativeBoxDark.active {
    border-color: #1891FF!important;
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.2);
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.2);
    transform: translate(-5px, -5px);
}

.initiativeBoxLight.active {
    background-color: #E6F6FF;
}

.initiativeBoxDark.active {
    background-color: #04314C;
}

.separator {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% { opacity: 0; }
}